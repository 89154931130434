import React from "react"
import './solutions.scss'
import FeatureCard from '../components/FeatureCard'
import selfiLogo from '../images/selfi-pers.svg'
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const PersonalDiscovery = () => {

    const data = useStaticQuery(graphql`
	query {
		selfi_screenshot: file(relativePath: { eq: "selfi-pers-screenshot.png" }) {
			childImageSharp {
				fluid(maxWidth: 1082, maxHeight: 651) {
				  ...GatsbyImageSharpFluid
				}
			  }
		},
	  }
	`)

    return (
        <div className="solutions">
            <SEO title="Personal Discovery" />
            <section className="bg--tertiary" id="personal-discovery-web">
                <div className="container content solutions-top-container">
                    <div className="col-sm-8 col-md-8 screenshot-container">
                            <div className="selfi-screenshot">
                                <Image
                                    fluid={data.selfi_screenshot.childImageSharp.fluid}
                                    alt="Personal discovery" />
                            </div>
                    </div>
                    <div className="col-sm-5 col-md-5">
                        <div className="row">
                            <span className="text-gray-light">OUR SOLUTIONS</span>    
                        </div>
                        <div className="row">
                            <img src={selfiLogo} className={"selfi-logo"} alt="" />
                        </div>
                        <div className="row">
                            <h1 className="solution-title">Personal discovery</h1>
                            <p>Selfi is a platform for personal self-discovery and understanding. The psychometric assessments are guided and developed through research and powered by Mercurics' AI engine.</p>
                            <p>Users can get opportunities in domains such as job culture fit & matching, team guidance & cohesion, self improvement & self help.</p>
                            {/* <button className="secondary" onClick={() => window.open('https://selfipersonal.ai/quiz', '_blank')}>Try it out — No registration required</button> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg--tertiary" id="personal-discovery-web">
                <div className="container content">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="section-title-secondary">Features</h1>
                        </div>
                    </div>
                    <div className="row features-container">
                        <FeatureCard col={4} title={"Short quizzes"} text={"Each quiz takes only a short two minutes to complete, allowing people to complete them during small pockets of time.  A profile is assembled bit by bit instead of being a long drawn-out process."} />
                        <FeatureCard col={4} title={"Updated frequently"} text={"New quizzes are released often, each exploring a new domain. There are always new discoveries to be made about the self and others."} />
                        <FeatureCard col={4} title={"Accuracy increases"} text={"Every quiz completed strengthens and validates the results of other quizzes  attempted before, making one's profile more holistic and accurate."} />
                        <FeatureCard col={4} title={"Apply them in life"} text={"Go deeper into quiz results and learn how to navigate life challenges and build better relationships with people with different personalities."} />
                    </div>
                </div>
            </section>
            <div id="personal-discovery-mobile">
                <section className="bg--tertiary" id="personal-discovery">
                    <div className="container content solutions-top-container">
                        <div className="screenshot-container">
                            <div className="selfi-screenshot">
                                <Image
                                    fluid={data.selfi_screenshot.childImageSharp.fluid}
                                    alt="Personal discovery" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <span className="text-gray-light">OUR SOLUTIONS</span>    
                            </div>
                            <div className="row">
                                <h1 className="solution-title">Personal discovery</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="selfi-mobile-container" id="personal-discovery">
                    <div className="container content">
                        <div className="selfi-mobile-card">
                            <div className="row">
                                <div className="col-12">
                                    <img src={selfiLogo} className={"selfi-logo"} alt="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p>Selfi is a platform for personal self-discovery and understanding. The psychometric assessments are guided and developed through research and powered by Mercurics' AI engine.</p>
                                    <p>Users can get opportunities in domains such as job culture fit & matching, team guidance & cohesion, self improvement & self help.</p>
                                    <button className="secondary" onClick={() => window.open('https://selfipersonal.ai/quiz', '_blank')}>Try it out — No registration required</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg--tertiary">
                    <div className="container content">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="section-title-secondary">Features</h1>
                            </div>
                        </div>
                        <div className="row features-container">
                            <FeatureCard col={4} title={"Short quizzes"} text={"Each quiz takes only a short two minutes to complete, allowing people to complete them during small pockets of time.  A profile is assembled bit by bit instead of being a long drawn-out process."} />
                            <FeatureCard col={4} title={"Updated frequently"} text={"New quizzes are released often, each exploring a new domain. There are always new discoveries to be made about the self and others."} />
                            <FeatureCard col={4} title={"Accuracy increases"} text={"Every quiz completed strengthens and validates the results of other quizzes  attempted before, making one's profile more holistic and accurate."} />
                            <FeatureCard col={4} title={"Apply them in life"} text={"Go deeper into quiz results and learn how to navigate life challenges and build better relationships with people with different personalities."} />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PersonalDiscovery